<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      'Companies': 'Empresas',
    },
    es: {
      'Companies': 'Compañías',
    }
  },
  components: {
    Layout
  },
  data() {
    return {
      loading: true,
      errored: false,
      empty: true,

      companies: []
    };
  },
  methods: {
    getList() {
      api
        .get('partners/companies')
        .then((response) => {
          if (response.data.status == 'success') {
            this.companies = response.data.list
          }

          this.loading = false
        })
    }
  },
  mounted() {
    this.getList()
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Companies') }}</h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div v-if="loading" class="text-center">
          <b-spinner small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
        </div>
        <div v-else-if="errored">
          <div class="card">
            <div class="card-body">
              {{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}
            </div>
          </div>
        </div>
        <div v-else-if="companies.length == 0">
          <div class="card">
            <div class="card-body">
              {{ t('No records found.') }}
            </div>
          </div>
        </div>
        <div v-else class="row">
          <div v-for="(com,id) in companies" :key="id" class="col-md-3">
            <router-link v-if="com.cover" tag="a" :to="'/partners/companies/' + com.id">
              <div class="card company-block">
                <img class="company-cover w-100" :src="com.cover" />
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.company-cover {
  max-height: 200px;
  border-radius: 0.25rem;
}
</style>